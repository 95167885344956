import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, delay, takeUntil, tap } from 'rxjs';
import { StorageService } from './services/storage.service';
import { LoaderService } from './services/loader.service';
import { AuthService } from './services/auth.service';
import { MatDrawer, MatDrawerContainer, MatSidenav, MatSidenavContainer } from "@angular/material/sidenav";
import { ComponentPortal } from "@angular/cdk/portal";
import { CustomSidenavService } from './services/custom-sidenav/custom-sidenav.service';
import { Destroy } from './services/destroy';
import { Position } from './utils/constant';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnChanges{

  displayCustomSideNav: boolean = false;
  public _customSideNav?: MatSidenav;
  @ViewChild(MatSidenavContainer) _snc?: MatSidenavContainer;
  _customSideComponent$ = new BehaviorSubject<any>(null);

  title = 'fixz';
  isLoggedIn: boolean = false;
  storage$: Subscription = new Subscription();
  isLoading = false;
  customSideNavPosition: Position = 'start';
  constructor(public storage: StorageService, public loading: LoaderService, private authService: AuthService, public _sideNav: CustomSidenavService, public destroy$: Destroy){
    this.checkIsUserLoggedIn();
  }



  ngOnInit(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.listenToLoading();
    this.checkIsUserLoggedIn();
    this._sideNav.getComponentPortal$().pipe(
      takeUntil(this.destroy$)
    ).subscribe((_) => {
      console.log(_);
      this.displayCustomSideNav = false;
      if(_ !== null && _?.ComponentPortal?.component !== null){
        this.displayCustomSideNav = true;
        this.customSideNavPosition = _.Position!;
      }
      this._customSideComponent$.next(_?.ComponentPortal);
    })
  
  
  }

  ngAfterViewInit() {
    this._customSideNav = this._snc?.start as MatSidenav;
  }

  closeOverlay() {
    this.displayCustomSideNav = false;
    this._sideNav.setComponentPortal(null);
  }

  listenToLoading(): void {
    this.storage$ = this.loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }


ngOnChanges(changes: SimpleChanges): void {
  //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //Add '${implements OnChanges}' to the class.
  console.log(changes);
}
  

  async checkIsUserLoggedIn() {
    await this.authService.isLoggedIn$.subscribe(async resp => {
      console.log(resp);
      if(resp) {
      this.storage$ = await this.storage.getItem('local', 'userData').subscribe((user: any) => {
        console.log(user);
        if(user) {
          this.isLoggedIn = true;
        }else {
        this.isLoggedIn = false;
        }
      });
    } else {
    this.isLoggedIn = false;
    }
    })
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.storage$.unsubscribe();
  }
}

