<nav class="custom-nav-bar">
    <!-- page sidebar start -->
    <div class="page-sidebar">
        <div class="main-sidebar">

            <ul class="custom-scrollbar">
                <ng-container  *ngFor="let item of navItems"> 
                
                <li class="sidebar-item"  (click)="routeTo(item.routerLink)">
                    <a class="sidebar-link" [ngClass]="currentUrl.includes(item.routerLink) ? 'active' : ''" >
                        <i [class]="item.iconClass"></i>
                        <span>{{item?.name}}</span>
                    </a>
                </li>
            </ng-container>
            </ul>

        </div>
    </div>
    <!-- page sidebar end -->
</nav>