<div class="custom-modal-container">
    <div class="custom-modal-header">
        <div class="custom-modal-title">Accept</div>
        <button class="custom-close-button" (click)="modalClick('false')">×</button>
    </div>
    <div class="custom-modal-body">
        <p>Are you sure want to accept this Offer ?</p>
    </div>
    <div class="custom-modal-footer">
        <button class="custom-btn cancel-job-button" (click)="modalClick('true')">Yes, </button>
        <button class="custom-btn custom-btn-secondary" (click)="modalClick('false')">Cancel</button>
    </div>
</div>