import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { NewJobService } from '../new-job.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceDetailService implements Resolve<any> {

  constructor(private newJobService: NewJobService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const queryParams = route.queryParams['selected_service'];
    
    return this.newJobService.getServiceDetail(queryParams);
  }
}
