import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  navBarItems: any = [];
  sideNavArray = [];

  private isLoggedInSubject = new BehaviorSubject<boolean>(true);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();

  constructor(public http: HttpService, public storage: StorageService) { }

  apiBaseUrl = environment.apiBaseUrl;


  getOTP(data: any): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/send-otp`, data);
  }

  resendOTP(data: any): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/resend-otp`, data);
  }

  login(data: any): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/verify-otp`, data).pipe(
      map((resp: any) => {
        this.storage.setItem('local', 'userData', resp.data);
        this.isLoggedInSubject.next(true);
        return resp;    
      })
    
    );
  }

  logout() {
    // remove user from local storage to log user out
   
    return this.http.get(`${this.apiBaseUrl}/logout`).pipe(
      map((resp: any) => {
        this.isLoggedInSubject.next(false);
        this.storage.clear();
      })
    );
  }
}
