import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { ServiceGridLayoutComponent } from 'src/app/components/service-grid-layout/service-grid-layout.component';
import { CommonService } from 'src/app/services/common.service';
import { Destroy } from 'src/app/services/destroy';
import { NewJobService } from 'src/app/services/new-job.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-service-selection',
  templateUrl: './service-selection.component.html',
  styleUrls: ['./service-selection.component.css'],
  standalone: true,
  imports: [CommonModule, ServiceGridLayoutComponent],
  providers: [Destroy]
})
export class ServiceSelectionComponent {
  serviceList: any;
  selectedParentService: any;
  selectedServiceKey: string = '';
  allServicesList: any;
  constructor(private commonService: CommonService, private destroy$: Destroy, private router: Router, public jobService: NewJobService, private storageService: StorageService) {
    this.getServices();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.jobService.setData('service_id', undefined);
    this.jobService.setData('subservice_id', undefined);
  }

  getServices() {
    this.commonService.getAllServices().pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
      if(resp){
        this.allServicesList = resp.data;
        this.serviceList = resp.data;
      }
    })
  }

  userSelectedGrid(event: any) {
    if(event.subservices && event.subservices.length > 0) {
      this.selectedParentService = event;
      this.serviceList = event.subservices;
      this.jobService.setData('service_id', event.id);
      this.jobService.fixZJobDetails.service_id = event.id;
    } else {
      this.selectedServiceKey = event.service_key;
      if(event.subservices?.length === 0 && !this.selectedParentService) {
        this.jobService.setData('service_id', event.id);
      }else {
        this.jobService.setData('subservice_id', event.id);
      }
      this.router.navigate(['services/detail'], {queryParams:{selected_service: this.selectedServiceKey }, queryParamsHandling:'merge'});
    }
  }

  goBack() {
    this.selectedParentService = null;
    this.serviceList = this.allServicesList;
  }
}
