import { ComponentPortal } from "@angular/cdk/portal";

export const constants = {

quotationType : ['new_jobs', 'assigned_jobs', 'applied_jobs', 'accepted_jobs', 'completed_jobs', 'cancelled_jobs'],
    mobileNumberPattern : '^[0-9]+$',
jobRoutes : [{url: 'new-job-request', title: 'New Job Request', status: 'Open'}, {url: 'accepted-job-request', title: ' Accepted Job Request', status: 'Scheduled'}, {url: 'job-history', title: 'Job History', status: ''}, {url: 'payment-history', title: 'Payment History', status: ''}],
addressType: ['Home', 'Work', 'Hotel', 'Other']
}

export type Position = 'start' | 'end';
export type CustomComponentDetail = {
    ComponentPortal : ComponentPortal<any>,
    Position?: Position
}