<app-header></app-header>
<main class="pages-container">
  
<ng-container>
 <!-- <mat-sidenav-container class="example-container" [hasBackdrop]="true">
    <mat-sidenav #customSideNav opened mode="over" [position]="customSideNavPosition">
      <div class="container">
        <ng-template [cdkPortalOutlet]="_customSideComponent$ | async"></ng-template>
      </div>
    </mat-sidenav>
  </mat-sidenav-container> -->

   <div class=" overlay" *ngIf="displayCustomSideNav">
    <div class="makeOffer shadow" [ngClass]="customSideNavPosition === 'start' ? 'sideNavPositionStart' : 'sideNavPositionEnd'">
      <div class="">
        <button type="button" class="close mr-2 mt-1" aria-label="Close" (click)="closeOverlay()">
          <span aria-hidden="true">&times;</span>
        </button>
        <ng-template [cdkPortalOutlet]="_customSideComponent$ | async"></ng-template>
      </div>
      </div>
    </div> 
</ng-container>
  <app-side-nav *ngIf="isLoggedIn"></app-side-nav>
  <div [ngClass]="isLoggedIn == true ? 'sideNavClass w-100' : 'w-100'">
    <router-outlet></router-outlet>
    
    </div>
</main>

<div class="loader" *ngIf="isLoading">
  <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
  </svg>

</div>