import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private storageService: StorageService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const requiredDataKey = 'userData'; // Replace with the key for your authentication data
    return this.storageService.getItem('local',requiredDataKey).pipe(
      map(data => !!data), // Check if data exists and convert to boolean
      map(hasData => {
        if (hasData) {
          return true; // Allow access if data exists
        }else {
        return this.router.createUrlTree(['/login'], { queryParams: { returnUrl: state.url } });
        }
      })
    );
  }
}