import { Injectable } from '@angular/core';
import { Observable, Subject, of, startWith } from 'rxjs';

type Storage = 'local' | 'session';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private dataSubject = new Subject<any>();

  constructor() { }

  setItem(storage: Storage, key: string, value: any): void {
    if(storage === 'local') { 
    localStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
    // this.dataSubject.next(value); // Update data subject
  }

  getItem(storage: Storage,key: string): Observable<any| null> {
    if(storage === 'local') { 
    const item = localStorage.getItem(key);
    return of(JSON.parse(item!))
  } else {
    const item = sessionStorage.getItem(key);
    return of(JSON.parse(item!))
  }
  }

  removeItem(storage: Storage, key: string): void {
    if(storage === 'local'){
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
    // this.dataSubject.next(null); // Update data subject
  }

  clear(): void {
     localStorage.clear() ; sessionStorage.clear();
    // this.dataSubject.next(null); // Update data subject
  }
}
