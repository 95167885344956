<div class="grid-container">
    <ng-container *ngFor="let gridItem of gridData">
        <div class="grid-item" (click)="selectGridItem(gridItem)">
            <div class="grid-item-box-gradient">
                <div class="grid-item-box">
                    <img class="grid-item-box-image" [src]="gridItem.image"
                        alt="image for {{gridItem.service_key}} service" />
                </div>
            </div>
            <p class="grid-title">{{gridItem.title == 'Gardening/Landscaping' ? (gridItem.title | wordBreak:20 ) :
                gridItem.title}}</p>
        </div>
    </ng-container>
</div>