import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StorageService } from './storage.service';


type mediaType = 'CV'|'PASSPORT'|'ID'|'CERTIFICATE'|'PROFILE_PICTURE'|'DRIVING_LICENSE'|'OTHER'|'INSURANCE'|'IMAGE'|'VIDEO'|'IDENTITY_FRONT'|'IDENTITY_BACK'|'SELFIE';

@Injectable({
  providedIn: 'root'
})
export class CommonService {


  constructor(public http: HttpService, private storage: StorageService, private httpS: HttpClient) { }

  apiBaseUrl = environment.apiBaseUrl;
  
  getBanners(): Observable<any> {
    let localData;
    this.storage.getItem('session', 'bannerData').subscribe((resp: any) => {
      localData = resp;
    });
    if(localData) {
      return of(localData);
    } else {
      return this.http.get(`${this.apiBaseUrl}/get-contractor-banner`);
    }
  }

  getPolicies(user_type:string, page:string): Observable<any>{
    return this.http.get(`${environment.apiBaseUrl}/get-page?user_type=${user_type}&page=${page}`);
  }

  getFaqs(user_type:string, type:string): Observable<any>{
    return this.http.get(`${environment.apiBaseUrl}/get-faqs?user_type=${user_type}&type=${type}`);
  }

  getDocuments(employeeId?: number):Observable<any> {
    if(employeeId) {
      let params = new HttpParams().set('id', employeeId);
      return this.http.get(`${environment.apiBaseUrl}/contractor/my-documents-list`, {params: params})
    }else {
      return this.http.get(`${environment.apiBaseUrl}/contractor/my-documents-list`);
    }
  } 

  getCities(): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/get-cities`);
  }

  getAllServices(): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/get-services`);
  }


  // getMyReviews(page:string, employeeId?: any): Observable<any> {
  //   if(employeeId) {
  //     return this.http.get(`${environment.apiBaseUrl}/contractor/my-review-list?page=${page}&id=${employeeId}`);

  //   }else {
  //   return this.http.get(`${environment.apiBaseUrl}/contractor/my-review-list?page=${page}`);
  //   }
  // }
  

  postUploadMultipleDocument(files: any, id: any, type: mediaType): Observable<any> {
    let userData: any;
    this.storage.getItem('local','userData').subscribe(user => 
      userData = user
    );
    let formData = new FormData();
    formData.append('type', 'REPAIR_REQUESTS');
    formData.append('document_type', type);
    formData.append('fk_id', `${id}`);
    for(let i=0; i<files.length; i++) {
      formData.append('image', files[i]);
    }
    // let headers  = new HttpHeaders().set('enctype', 'multipart/form-data').set('Accept', 'application/json');
    let customHeaders  = new HttpHeaders().set('Authorization', `Bearer ${userData?.token}`).set('enctype', 'multipart/form-data');
    return this.httpS.post(`${environment.apiBaseUrl}/upload-multiple-documents`, formData, {headers: customHeaders});
  }

  postUploadSingleDocument(file: any, id: any, type: mediaType): Observable<any> {
    let userData: any;
    this.storage.getItem('local','userData').subscribe(user => 
      userData = user
    );
    let formData = new FormData();
    formData.append('type', 'REPAIR_REQUESTS');
    formData.append('document_type', type);
    formData.append('fk_id', `${id}`);
    formData.append('image', file);
    let customHeaders  = new HttpHeaders().set('Authorization', `Bearer ${userData?.token}`).set('enctype', 'multipart/form-data');
    return this.httpS.post(`${environment.apiBaseUrl}/upload-single-documents`, formData, {headers: customHeaders});
    // return this.httpS.post(`http://localhost:3000/upload`, formData, {
    //   reportProgress: true,
    //   observe: 'events'
    // });
  }

}
