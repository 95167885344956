import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { LoginComponent } from './components/login/login.component';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { MobileInputComponent } from './components/mobile-input/mobile-input.component';
import { FormsModule } from '@angular/forms';
import { ImgSliderComponent } from './components/img-slider/img-slider.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { PipeModule } from './pipes/pipe.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatSidenavModule } from "@angular/material/sidenav";
import { PortalModule } from "@angular/cdk/portal";
import { Destroy } from './services/destroy';
import { JobAcceptConfirmationComponent } from './components/modal-popups/job-accept-confirmation/job-accept-confirmation.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideNavComponent,
    LoginComponent,
    OtpVerificationComponent,
    MobileInputComponent,
    ImgSliderComponent,
    JobAcceptConfirmationComponent
  ],
  imports: [
    BrowserModule,
    NgOptimizedImage,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    MatSidenavModule,
    PortalModule,
    ToastrModule.forRoot(),
    PipeModule,
    BrowserAnimationsModule,
    NgxMatTimepickerModule.setLocale('en-GB')
  ],
  providers: [
    Destroy,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
