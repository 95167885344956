import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  get(url: string, httpOptions?: any): Observable<any> {
    return this.http.get(url, httpOptions).pipe(
      tap(data => console.log(data))
    );
  }

  post(url: string, body: any, options?: any): Observable<any> {
    console.log(options)
    return this.http.post(url, body, options).pipe(
      tap(data => console.log(data))
    );
  }

}
