import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordBreak'
})
export class WordBreakPipe implements PipeTransform {

  transform(value: string, maxLength: number = 20): string {
    
    if (!value || value.length <= maxLength) {
      return value;
    }

    // Split the string into words
    const words = value.split(' ');

    // Check if any word exceeds the maximum length
    const hasLongWord = words.some(word => word.length > maxLength);

    if (!hasLongWord) {
      // No word exceeds length, return original string
      return value;
    }

    // Shorten words if necessary
    return words.map(word => word.length > maxLength ? `${word.substring(0, maxLength)}...` : word).join(' ');
  }

}
