import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PipeModule } from 'src/app/pipes/pipe.module';

@Component({
  selector: 'app-service-grid-layout',
  templateUrl: './service-grid-layout.component.html',
  styleUrls: ['./service-grid-layout.component.css'],
  standalone: true,
  imports: [CommonModule, PipeModule]
})
export class ServiceGridLayoutComponent {

  @Input() gridData: any;

  @Output() selectGrid = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log(this.gridData);
  }

  selectGridItem(item: any) {
    console.log(item);
    this.selectGrid.emit(item);
  }
}
