import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isOpen = false; 
  selectedOption: string = '';
  dropdownOptions = [
    {
      'label': 'My Profile',
      'value': 'profile'
    },
    {
      'label': 'Notifications',
      'value': 'notification'
    },
    {
      'label': 'Logout',
      'value': 'logout'
    }
  ];
  userName: string = '';
  
  isLoggedIn: boolean = false;
  constructor(private authService: AuthService, private elementRef: ElementRef, private storage: StorageService, private router: Router){
  }
  
  @HostListener('blur')
  handleBlur() {
    this.isOpen = false;
  }

  @HostListener('document:click', ['$event'])
handleClickOutside(event: MouseEvent) {
  const clickedInside = this.elementRef.nativeElement.contains(event.target);
  if (!clickedInside) {
    this.isOpen = false;
  }

}

ngOnInit(): void {
  this.getUserData();
}

getUserData() {
  this.storage.getItem('local', 'userData').subscribe(async (userData: any) => {
    console.log(userData);
    if(await userData){
      this.isLoggedIn = true;
      this.userName = userData.name;
    } else {
      this.isLoggedIn = false;
    }
  })
}

  logout(){
    this.authService.logout().subscribe(resp => {
        this.router.navigate(['/login']);
    });
  }



  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectOption(value: string) {
    this.selectedOption = value;
    this.isOpen = false;  
    if(value === 'logout'){
      this.logout();
    } else if(value === 'profile'){
      this.router.navigate(['/profile']);
    }
  }
}
