import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
 userData: any;
  constructor(public storageService: StorageService, public loader: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loader.setLoading(true);
    this.storageService.getItem('local','userData').subscribe(user => 
      this.userData = user
    );
    const isApiUrl = request.url.startsWith(environment.apiBaseUrl);
        if (this.userData && isApiUrl) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${this.userData.token}` }
            });
        }
        request = request.clone({
          setHeaders: {'Content-Type': 'application/json', 'lang':'en'}
        })
    return next.handle(request).pipe(
      map((evt: HttpEvent<any>) => {
        if(evt instanceof HttpResponse){
          this.loader.setLoading(false);
        }
        return evt;
      })
    );
  }
}
