
export interface navs {
    id: number,
    name: string,
    icon: string,
    routerLink: string,
    iconClass: string,
    hasSubMenu: boolean,
    parentId: number
}

export const navigationItems: navs[] = [
    {
        id: 1,
        name: 'Create New Job',
        icon: '',
        routerLink: '/services',
        iconClass: 'sidebar-dashboard',
        hasSubMenu: false,
        parentId: 0
    },
    {
        id: 2,
        name: 'New Job Request',
        icon: '',
        routerLink: '/new-job-request',
        iconClass: 'sidebar-quotations',
        hasSubMenu: false,
        parentId: 0
    },
    {
        id: 3,
        name: 'Accepted Job Request',
        icon: '',
        routerLink: '/accepted-job-request',
        iconClass: 'sidebar-quotations',
        hasSubMenu: false,
        parentId: 0
    },
    {
        id: 4,
        name: 'Chats',
        icon: '',
        routerLink: '/chats',
        iconClass: 'sidebar-chat',
        hasSubMenu: false,
        parentId: 0
    },
    {
        id: 5,
        name: 'Manage Address',
        icon: '',
        routerLink: '/manage-address',
        iconClass: 'sidebar-reviews',
        hasSubMenu: false,
        parentId: 0
    },
    {
        id: 6,
        name: 'Job History',
        icon: '',
        routerLink: '/job-history',
        iconClass: 'sidebar-history',
        hasSubMenu: false,
        parentId: 0
    },
    {
        id: 7,
        name: 'Payment History',
        icon: '',
        routerLink: '/payment-history',
        iconClass: 'sidebar-history',
        hasSubMenu: false,
        parentId: 0
    },
    {
        id: 8,
        name: 'Dispute Request',
        icon: '',
        routerLink: '/dispute-request',
        iconClass: 'sidebar-history',
        hasSubMenu: false,
        parentId: 0
    },
    {
        id: 9,
        name: 'My Profile',
        icon: '',
        routerLink: '/profile',
        iconClass: 'sidebar-profile',
        hasSubMenu: false,
        parentId: 0
    }
]