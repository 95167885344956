import { Component } from '@angular/core';
import { DialogRef } from 'src/app/services/dialog/dialog-ref';

@Component({
  selector: 'app-job-accept-confirmation',
  templateUrl: './job-accept-confirmation.component.html',
  styleUrls: ['./job-accept-confirmation.component.css']
})
export class JobAcceptConfirmationComponent {

  constructor(private dialogRef: DialogRef) {}
  
  modalClick(event: string) {
    console.log(event);
    this.dialogRef.close(event);
  }
}
