import { Component } from '@angular/core';
import { takeUntil } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { Destroy } from 'src/app/services/destroy';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-img-slider',
  templateUrl: './img-slider.component.html',
  styleUrls: ['./img-slider.component.css'],
  viewProviders: [Destroy]
})
export class ImgSliderComponent {
  banner:any =[];
  
  constructor(public commonService: CommonService, private destroy$: Destroy, private storage: StorageService){

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getBannerImages();
  }

  getBannerImages() {
    this.commonService.getBanners().pipe(takeUntil(this.destroy$)).subscribe((data:any) => {
      this.storage.setItem('session', 'bannerData', data);
      this.banner = data.data;
      console.log(this.banner);
    });
  }
}
