<section class="component-container">
    <div class="col-12 row" style="padding-left: 25px;">
        <img class="back-button" src="../../../assets/icons/back_arrow.svg" alt="back-button" (click)="goBack()" *ngIf="selectedParentService"/>
        <h2 style="margin: 5px;"> Create Job </h2>
    </div>
    <div class="service-block">
        <h4 style="padding-left: 15px; margin-top: 10px;" *ngIf="selectedParentService">Which type of {{selectedParentService?.service_key}} do you need?</h4>
    <ng-container>
        <app-service-grid-layout [gridData]="serviceList" (selectGrid)="userSelectedGrid($event)" ></app-service-grid-layout>
    </ng-container>
    
</div>
</section>